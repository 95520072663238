<template>
	<div class="item" v-for="(a, i) in warn" :key="i">
		<div class="top">
			<div class="title">{{a.title}}</div>
		</div>
		<div class="content" v-html="a.text"></div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'AvisosItem',
	data() {
		return {
			warn: []
		}
	},
	methods:{
		async getWarn(){
			const req = await axios.get('modules/notice/get-view')
			this.warn = req.data
		}
	},
	mounted(){
		this.getWarn()
	}
}
</script>

<style scoped>

.item {
	width: calc(70% - 20px);
	height: auto;
	background-color: #fff;
	box-shadow: 0 2px 6px rgba(0,0,0,.15);
	border-radius: 12px;
	margin-bottom: 20px;
}
.item:last-of-type {margin: 0;}
body.dark-mode .item {background-color: #3b3b3b;color: #fff;}

.item .top {
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #eee;
	justify-content: flex-start;
	padding: 0 20px;
	line-height: 40px;
	user-select: none;
	font-size: 15px;
	font-weight: 600;
}
body.dark-mode .item .top {border-bottom: 1px solid #6d6d6d;}

.item .content {
	width: 100%;
	height: auto;
	padding: 20px;
	font-size: 13px;
	line-height: 20px;
}
</style>