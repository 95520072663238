<template>
	<div class="nav" :class="{active: $store.state.navbar, dark: $store.state.dark == true}" >
		<div>
			<div class="open" v-if="$store.state.navbar" @click="cleanSubs(), $store.state.navbar = !$store.state.navbar"><i class="fa-solid fa-xmark"></i></div>
			<div class="open" v-else @click="cleanSubs(), $store.state.navbar = !$store.state.navbar"><i class="fa-solid fa-bars-staggered"></i></div>
		</div>

		<button v-show="$store.state.navbar" @click="$store.commit('dark', !$store.state.dark)"><i class="fa-solid fa-moon"></i></button>

		<div class="logo"></div>

		<nav class="menu">
			<ul class="ulMain">
				<li class="limain">
					<router-link :to="{path: '/', name: 'home'}">
						<div class="topLi dash" @click="cleanSubs(), this.$store.state.system.title = 'Ola, '+$store.state.user.user, $store.state.navbar = false, $store.state.system.icon = 'fa-house'">
							<i class="fa-solid fa-house icon"></i>
							<p>Pagína inicial</p>
						</div>
					</router-link>
				</li>
				<li class="limain" v-for="(cc, index) in api_chan" :key="index" v-show="cc.channels.length > 0">
					<div class="topLi" @click="cleanSubs(), $store.state.navbar = true, subClick($event)">
						<i class="fa-solid icon" :class="cc.cat.icon"></i>
						<p>{{cc.cat.category}}</p>
					</div>
					<ul class="subnav">
						<li @click="$store.state.navbar = false, this.$store.state.system.title = c.name, cleanSubs(), $store.state.system.icon = cc.cat.icon" v-for="(c, i) in cc.channels" :key="i">
							<router-link :to="{path: c.path, name: c.link_name}">
								<p>{{c.name}}</p>
							</router-link>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
		<div class="dev">
			<div class="my-icon"></div>
			<span >Developed by Paulo Ferraço.</span>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'NavBar',
	data() {
		return {
			strNav: 'dash',
			api_chan: [],
		}
	},
	methods: {
		subClick(e) {
			let parent =  e.target.parentNode.parentNode;
			if(!parent.hasAttribute('ulmain')){
				const ulSub = parent.querySelector('.subnav');
				let select = parent.querySelector('.topLi')
				if(ulSub.classList.contains('active')){
					ulSub.classList.remove('active')
					select.classList.remove('select')
				}else{
					ulSub.classList.add('active')
					select.classList.add('select')
				}
			}
		},
		cleanSubs(){
			let tops = document.querySelectorAll('li .select')
			const subs = document.querySelectorAll('ul.subnav')
			for (let i = 0; i < subs.length; i++) {
				subs[i].classList.remove('active')
			}
			for (let i = 0; i < tops.length; i++) {
				tops[i].classList.remove('select')
			}
		},
		async getNav(){
			const req = await axios.get('async_util/navbar/get')
			this.api_chan = req.data
		},
		body(){
			
		}
	},
	mounted(){
		this.getNav()
		document.addEventListener ('keypress', (event) => {
			if(event.key == 'b'){
				this.$store.state.navbar = !this.$store.state.navbar
				this.cleanSubs()
			}
			return;
		})
	}
}
</script>

<style scoped>

.nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 4%;
	height: 100%;
	background-color: #fff;
	box-shadow: 3px 0 10px rgba(0,0,0,.1);
	border-radius: 0 20px 20px 0;
	transition: all .5s ease;
	z-index: 999;
}
.nav.active {width: 18%;}
.nav.dark {
	background-color: #292929;
}
.nav.dark nav ul .limain .topLi > p,
.nav.dark ul .limain .icon {color: #fff !important;}

.nav .open {
	position: relative;
	width: 40px;
	height: 40px;
	top: 14px;
	left: 18px;
	text-align: center;
	line-height: 40px;
	z-index: 999;
	transition: all .5s ease;
}
.nav.dark .open,
.nav.dark > button {color: #fff;}

.nav > button {
	position: absolute;
	float: right;
	width: 40px;
	height: 40px;
	right: 30px;
	top: 14px;
	font-size: 18px;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
}

.nav .logo {
	position: relative;
	width: 100%;
	height: 50px;
	top: 40px;
	background-image: url('@/assets/logoSmall.png');
	background-position: 24px center;
	background-repeat: no-repeat;
	transition: all .3s ease;
}

.nav.active .logo {
	width: 100%;
	background-image: url('@/assets/logo.png');
	background-position: center;
}

.nav nav {
	position: relative;
	width: 100%;
	height: auto;
	margin-top: 60px;
}

.nav nav {
	position: relative;
	height: calc(100% - 232px);
	overflow-x: hidden;
	overflow-y: overlay;
	z-index: 1;
}

.nav nav::-webkit-scrollbar{width: 4px;}
.nav nav::-webkit-scrollbar-button { display: none; }
.nav nav::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0.0); }
.nav nav::-webkit-scrollbar-thumb { background-color: rgba(0,0,0,0.3); width: 4px; border-radius: 4px;}

nav ul,
nav ul li {
	float: left;
	width: 100%;
	height: auto;
	white-space: nowrap;
	color: #444;
	transition: all .5s ease;
}

.nav nav ul .limain {
	position: relative;
	width: 100%;
	height: auto;
	margin-top: 0px;
	transition: all .4s ease;
}
.nav.active nav ul .limain {width: calc(100% - 50px);margin-top: 14px;}

.nav nav ul .limain .topLi {
	float: left;
	width: 100%;
	height: 50px;
	padding: 6px 0;
	display: flex;
	justify-content: space-evenly;
	transition: all .4s ease;
}

.nav.active nav ul .limain .topLi:hover,
.nav.active nav ul .limain .topLi.active,
.nav.active nav ul .limain .topLi.select {
	background-color: var(--color1);
	border-radius: 0 8px 8px 0;
}
.nav.active nav ul .limain .topLi.active:not(.dash) {border-radius: 0 8px 0 0;}

nav ul .limain .icon {
	width: 40px;
	height: 40px;
	margin: -1px 0 0 18px;
	text-align: center;
	line-height: 40px;
	font-size: 20px;
	color: #444;
}
nav.active ul .limain .icon {margin-left: 0;}
nav:not(.active) ul .limain .topLi.active .icon {color: var(--color1);}

.nav.active ul .limain .icon {color: #444;}
.nav.active ul .limain .topLi:hover .icon,
.nav.active ul .limain .topLi.active .icon,
.nav.active nav ul .limain .topLi.select .icon {color: #fff;}

.nav nav ul .limain .topLi > p {
	float: left;
	width: calc(100% - 70px);
	padding: 10px 0 0 10px;
	user-select: none;
	font-size: 15px;
	color: #444;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
}
.nav.active .limain .topLi p {opacity: 1;visibility: visible;}
/* HOVER E ACTIVO */
.nav.active .limain .topLi:hover > p,
.nav.active .limain .topLi.active > p,
.nav.active nav ul .limain .topLi.select > p {color: #fff;font-weight: 600;}

.menu {
	padding: 10px 0;

}

.menu .subnav {
	position: relative;
	width: calc(100% - 15px);
	height: 0;
	background-color: #f2faff;
	border-radius: 0 0 10px 0;
	margin-top: 0px;
	opacity: 0;
	visibility: hidden;
	display: none;
	flex-wrap: wrap;
	justify-content: flex-start;
	transition: all .3s ease-in;
	padding-bottom: 4px;
}
.menu .subnav.active {
	opacity: 1;
	visibility: visible;
	height: 100%;
	display: block;
}
.nav.dark .menu .subnav {background-color: #3b3b3b}
.nav .menu .subnav::before {
	position: absolute;
	content: '';
	right: -9px;
	top: 0;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 9px 9px 0 0;
	border-color: #f2faff transparent transparent transparent;
	transition: all .3s ease-in;
}
.nav.dark .menu .subnav::before {border-top-color: #3b3b3b;}

/* .nav.active .limain .topLi.select ~ .menu .subnav.active {border-radius: 0 50px 50px 0;} */

.menu .subnav li {
	position: relative;
	width: 100%;
	height: 36px;
	padding: 0 0 0 34px;
	margin-top: 4px;
}

.menu .subnav li p,
.menu .subnav li span {
	font-size: 14px;
	line-height: 36px;
	user-select: none;
	transition: all .3s ease;
}
.nav.dark .menu .subnav li p,
.nav.dark .menu .subnav li span {color: #fff;}
.menu .subnav > li:hover p {color: var(--color1);font-weight: 600;padding-left: 4px;}

.nav .dev {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40px;
	color: #444;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	z-index: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.nav .dev .my-icon {
	width: 24px;
	height: 24px;
	background-image: url('https://ppanel.pauloferraco.com.br/imagem/uploads/upload-2a5ddd814518d76423c17ad27b7f9367.png');
	background-position: center;
	background-size: cover;
}
.nav.dark .dev .my-icon {background-image: url('https://ppanel.pauloferraco.com.br/imagem/uploads/upload-f78439cbf5f4692d5ade629c46fffb18.png');}

.nav .dev span {
	position: relative;
	padding: 0 10px;
	font-size: 13px;
	font-weight: 400;
	display: inline;
}
.nav:not(.active) .dev span {display: none;}
.nav.dark .dev span {color: #fff;}

@media only screen and (max-width: 900px) {
	.nav {
		width: 0 !important;
		border-radius: 0;
	}
	.nav.active {width: 100% !important;}
	.nav .open {color: #fff;left: 10px;}
	.nav.active .open {color: #444;}

	.nav .dev {
		display: none;
	}
	.nav.active .dev {
		width: 100%;
		display: block;
	}
}

@media only screen and (max-width: 1400px) {
	.nav {
		width: 80px;
	}
	.nav.active {width: 25%;}

	.nav .dev {
		display: none;
	}
	.nav.active .dev {
		width: 100%;
		display: block;
	}
}
</style>